import { useContext, useEffect, useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import Networking from "../services/Networking";
import GlobalContext from "../services/GlobalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import Languages from "../services/Languages";

export default function Quote() {
  const [language, setLanguage] = useState<string>(Languages.VN)
  const [message, setMessage] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [quote, setQuote] = useState<any>(null);
  const { speak }: any = useContext(GlobalContext);

  function onRead(e: any) {
    speak(quote?.message);
  }
  useEffect(() => {
    async function getQuote() {
      try {
        const res: any = await Networking.getTodayQuote();
        if (res.success) {
          setQuote(res.data);
        } else {
          setQuote(null);
        }
      } catch (error) {
        setQuote(null);
      }
    }
    getQuote();
  }, []);

  async function onTranslate(e: any, language: string) {
    e.preventDefault();
    setLanguage(language);
    if (quote[language]) {
      setMessage(null);
      return;
    }
    setLoading(true);
    try {
      const res: any = await Networking.translateQuote(language);
      console.log(res);
      if (res.success) {
        setQuote(res.data);
        setMessage(null);
      } else {
        setQuote(null);
        setMessage(res.message);
      }
    } catch (error) {
      setQuote(null);
      setMessage("Server Error");
    }
    setLoading(false);
  }

  return (
    <Card style={{ marginBottom: "10px" }}>
      <Card.Header>Today's Quote ({new Date().toLocaleString()})</Card.Header>
      <Card.Body>
        {quote &&
          <p>
            <Button type="button" variant="primary" onClick={onRead}>
              <FontAwesomeIcon icon={faVolumeHigh} />
            </Button>
            <br />
            <i>
              <strong>
                {quote.author}:{" "}
              </strong>
              <q>
                {quote.message}
              </q>
            </i>

          </p>}
        <Button variant="primary" onClick={e => onTranslate(e, Languages.VN)}>
          Tiếng Việt
        </Button>
        <Button variant="primary" onClick={e => onTranslate(e, Languages.KR)} style={{marginLeft: 10}}>
          한국어
        </Button>
        <Button variant="primary" onClick={e => onTranslate(e, Languages.JP)} style={{marginLeft: 10}}>
          日本語
        </Button>
        {loading && <Spinner animation="grow" />}
        {
          quote &&
          <Card.Text>
            {/* <Button type="button" variant="primary" onClick={onReadOther}>
                    <FontAwesomeIcon icon={faVolumeHigh} />
                </Button>
            <br/> */}
            {quote[language]}
          </Card.Text>
        }
        {!quote &&
          <Card.Text>
            <strong>
              {message}
            </strong>
          </Card.Text>}
      </Card.Body>
    </Card>
  );
}
