import { useEffect, useState, useContext } from "react";
import { Card, Button } from "react-bootstrap";
import Networking from "../services/Networking";
import GlobalContext from "../services/GlobalContext";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DailyWords() {
  const [words, setWords] = useState<any>(null);
  const { speak }: any = useContext(GlobalContext);

  function onRead(e: any) {
    speak(words.join(", "));
  }
  useEffect(() => {
    async function getDailyWords() {
      try {
        const res: any = await Networking.getTodayWords();
        if (res.success && res.data) {
          setWords(res.data.words);
        } else {
          setWords(null);
        }
      } catch (error) {
        setWords(null);
      }
    }
    getDailyWords();
  }, []);

  return (
    <Card style={{ marginBottom: "10px" }}>
      <Card.Header>
        Today's Words
      </Card.Header>
      <Card.Body>
        <Button type="button" variant="primary" onClick={onRead}>
          <FontAwesomeIcon icon={faVolumeHigh} />
        </Button> &nbsp;&nbsp;&nbsp;&nbsp;
        {words &&
          <span>
            {words.join(", ")}
          </span>}
      </Card.Body>
    </Card>
  );
}
