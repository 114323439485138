import { createContext, useMemo, useReducer } from "react";
import { getFromLocal, LS_KEY } from "./LocalService";

interface ICode {
  [key: string]: string;
}
const LANGUAGES_CODES: ICode = require("./iso-language-codes.json");
const DEFAULT_LANGUAGE = "English (United States)";

const GlobalContext = createContext({});

export enum ACTION {
  SAVE_TOKEN,
  CLEAR_TOKEN
}

export function reducer(state: any, action: { type: ACTION; payload?: any }) {
  switch (action.type) {
    case ACTION.SAVE_TOKEN:
      return { ...state, token: action.payload };
    case ACTION.CLEAR_TOKEN:
      return { ...state, token: null };

    default:
      return state;
  }
}

export default GlobalContext;

const utterance = new SpeechSynthesisUtterance();
function fetchVoices() {
  const voices = speechSynthesis.getVoices();
  const vietnameseVoices = voices.filter(x => x.lang.includes("vi"));
  //console.log(vietnameseVoices);
}
if ("speechSynthesis" in window) {
  if (speechSynthesis.onvoiceschanged !== undefined) {
    speechSynthesis.onvoiceschanged = fetchVoices;
  } else {
    // Some browsers may not support the 'onvoiceschanged' event,
    // in that case, use a timeout to fetch the voices
    setTimeout(fetchVoices, 1000);
  }
}
utterance.lang = "en-US"; //American
//utterance.lang = 'en-IN';//indian
utterance.pitch = 1.0; // a little lower
utterance.rate = 0.8; // a little faster
utterance.volume = 1.0; // a little quieter
//utterance.voice = voices[66];

export function GlobalProvider({ children }: { children: any }) {
  const [state, dispatch] = useReducer(reducer, { utterance });

  function load() {
    const token = getFromLocal(LS_KEY.DICVERSE_TOKEN);
    dispatch({ type: ACTION.SAVE_TOKEN, payload: token });
  }
  //run only one when start the application
  useMemo(() => load(), []);
  function getLanguageCode(language: string): string {
    return LANGUAGES_CODES[language];
  }

  function speak(text: string, language: string = DEFAULT_LANGUAGE) {
    window.speechSynthesis.cancel();
    utterance.lang = getLanguageCode(language);
    utterance.text = text;
    window.speechSynthesis.speak(utterance);
  }

  return (
    <GlobalContext.Provider
      value={{
        state,
        speak
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
