
const LS = {};
export default LS;

export enum LS_KEY{
    DICVERSE_TOKEN = `dicverse-com-token`,
}

export function saveToLocal(key: LS_KEY, value: string): void{
    localStorage.setItem(key, value);
} 

export function removeFromLocal(key: LS_KEY): void{
    localStorage.removeItem(key);
} 

export function getFromLocal(key: LS_KEY): string | null{
    const res = localStorage.getItem(key);
    return res;
} 