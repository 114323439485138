import { useEffect, useState, useContext } from "react";
import { Card, Button } from "react-bootstrap";
import Networking from "../services/Networking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import GlobalContext from "../services/GlobalContext";

export default function DailyStory() {
  const [story, setStory] = useState<any>(null);
  const { speak }: any = useContext(GlobalContext);

  function onRead(e: any) {
    speak(story);
  }
  useEffect(() => {
    async function getDailyStory() {
      try {
        const res: any = await Networking.getDailyStory();
        if (res.success && res.data) {
          setStory(res.data.story);
        } else {
          setStory(null);
        }
      } catch (error) {
        setStory(null);
      }
    }
    getDailyStory();
  }, []);

  return (
    <Card style={{ marginBottom: "10px" }}>
      <Card.Header>Funny Story</Card.Header>
      <Card.Body>
        <Button type="button" variant="primary" onClick={onRead}>
          <FontAwesomeIcon icon={faVolumeHigh} />
        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
        {story &&
          <span>
            {story}
          </span>}
      </Card.Body>
    </Card>
  );
}
