import axios from 'axios';
import Languages from './Languages';

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
axios.defaults.baseURL = 'https://dicverse.com/api';
//axios.defaults.baseURL = 'http://localhost:5001/api';
export default class Networking {
    static getWord(word: string, language: string) {
        let url = `/words/${word}`;
        if(language !== Languages.EN){
            url = `/words/${word}/translate?language=${language}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(function (response) {
                    if (response.data.success) {
                        const { definitions } = response.data.data;
                        for (let i = 0; i < definitions.length; i++){
                            if(definitions[i].image?.length > 0){
                                definitions[i].image = `${axios.defaults.baseURL}/images/${definitions[i].image}`
                            }
                        }
                    }
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static getTodayQuote() {
        let dt = new Date();
        let today = [months[dt.getMonth()], dt.getDate()].join("");
        return new Promise((resolve, reject) => {
            axios.get(`/quotes/${today}`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static translateQuote(language: String) {
        let dt = new Date();
        let today = [months[dt.getMonth()], dt.getDate()].join("");
        return new Promise((resolve, reject) => {
            axios.get(`/quotes/${today}/translate?language=${language}`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static getTodayWords() {
        return new Promise((resolve, reject) => {
            axios.get(`/words/get/today-words`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static getDailyStory() {
        return new Promise((resolve, reject) => {
            axios.get(`/stories/today`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
    static getInspiredTalks() {
        return new Promise((resolve, reject) => {
            axios.get(`/inspired-talks`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }
}
