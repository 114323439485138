import React, { useContext, useState } from "react";
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import GlobalContext from "../services/GlobalContext";
import Networking from "../services/Networking";
import IWord from "../data-types/IWord";
import Languages from "../services/Languages";

interface WordDefinitionProps {
  def: {
    function: string;
    meaning: string;
    example: string;
  };
}

function WordDefinition({ def }: WordDefinitionProps): any {
  return (
    <React.Fragment>
      <strong>Type: </strong>{def.function} <br />
      <strong>Meaning: </strong>{def.meaning} <br />
      <strong>Example: </strong>{def.example}
    </React.Fragment>
  )
}


export default function Word() {
  const [search, setSearch] = useState("");
  const [word, setWord] = useState<IWord | null>(null);
  const [message, setMessage] = useState<any>(null);
  const { speak }: any = useContext(GlobalContext);
  const [loading, setLoading] = useState<any>(false);
  const [language, setLanguage] = useState(Languages.EN);

  function onChangeSearch(e: any) {
    setSearch(e.target.value);
  }

  function onChangeLanguage(e: any) {
    setLanguage(e.target.value);
    setSearch("")
    setWord(null);
  }

  async function onSearch(e: any) {
    e.preventDefault();
    if (search.length === 0) {
      alert("Please enter a word");
      return;
    }
    setLoading(true);
    try {
      const res: any = await Networking.getWord(search, language);
      if (res.success) {
        setWord(res.data);
        setMessage(null);
      } else {
        setWord(null);
        setMessage(res.message);
      }
    } catch (error) {
      setWord(null);
      setMessage('Server Error');
    }
    setLoading(false);
  }

  function onRead(e: any) {
    speak(word?.name);
  }
  return (
    <Card style={{ marginBottom: "10px" }}>
      <Card.Header>
        Dictionary
      </Card.Header>

      <Card.Body>
        <Form.Select value={language} onChange={onChangeLanguage} className="mb-3">
          <option value={Languages.EN}>English - English</option>
          <option value={Languages.VN}>English - Vietnamese</option>
          {/* <option value={languages.JP}>English - Japanese</option>
                <option value={languages.KR}>English - Korean</option>
                <option value={languages.SP}>English - Spanish</option>
                <option value={languages.CN}>English - Chinese</option> */}
        </Form.Select>
        <Form onSubmit={onSearch} className="mb-3">
          <Row className="align-items-center justify-content-center">
            <Col xs={8}>
              <Form.Control type="text"
                placeholder="Enter a word"
                value={search}
                onChange={onChangeSearch}
                pattern="[a-zA-Z]{1,50}[\s]*"
                title="Please type only an English word"
                autoCapitalize="none"
              >

              </Form.Control>
            </Col>
            <Col xs={4}>
              <Button type="submit" variant="primary">Search</Button>
            </Col>
          </Row>
        </Form>
        {loading && <Spinner animation="grow"></Spinner>}
        {word &&
          <Card.Text>
            <Button type="button" variant="primary" onClick={onRead}>
              <FontAwesomeIcon icon={faVolumeHigh} />
            </Button>
            &nbsp;&nbsp;<i>{word.phonetic}</i>
          </Card.Text>
        }
        {word && <Card.Text>
          <WordDefinition
            def={language === Languages.EN ? word.definitions[0] : word.definitions[0].others[language.toLowerCase()]}
          />
        </Card.Text>}
        {!word && <Card.Text><strong>{message}</strong></Card.Text>}
      </Card.Body>
    </Card>
  )
}