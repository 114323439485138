import React, { useContext, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import GlobalContext from "../services/GlobalContext";

export default function Pronunciation() {
  const [message, setMessage] = useState<any>(null);
  const { speak }: any = useContext(GlobalContext);

  function onRead(e: any) {
    speak(message);
  }
  return (
    <Card style={{ marginBottom: "10px" }}>
      <Card.Header>English Text Reader</Card.Header>
      <Card.Body>
        <Form className="mb-3">
          <Row className="align-items-center justify-content-center">
            <Col xs={8}>
              <Form.Control
                type="text"
                placeholder="Enter a text"
                value={message}
                onChange={e => setMessage(e.target.value)}
                autoCapitalize="none"
              />
            </Col>
            <Col xs={4}>
              <Button variant="primary" onClick={onRead}>
                <FontAwesomeIcon icon={faVolumeHigh} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
