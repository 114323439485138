import { Container, Tab, Tabs } from "react-bootstrap";
import Word from "./Word";
import Quote from "./Quote";
import DailyWords from "./DailyWords";
import DailyStory from "./DailyStory";
import VideoList from "./VideoList";
import BestSpeeches from "./BestSpeeches";
import Pronunciation from "./Pronunciation";

export default function Dictionary() {
  const videos = [
    {
      id: "UF8uR6Z6KLc",
      title: "Steve Jobs 2005 Stanford Commencement Address"
    },
    {
      id: "5a5VSkkdpB4",
      title: "President Obama Makes Historic Speech to America's Students"
    },
    {
      id: "fxm3TYqD-Kw",
      title: "Oprah Winfrey Best Ever Motivational Speeches"
    }
  ];
  return (
    <Container fluid>
      <header className="justify-content-center">
        <center>
          <h3>Welcome to DicVerse</h3>
        </center>
      </header>
      <main>
        <Tabs defaultActiveKey="home" id="app-tabs">
          <Tab eventKey="home" title="Home">
            <Quote />
            <Word />
            <DailyStory />
            <DailyWords />
          </Tab>
          {/* <Tab eventKey="videos" title="Videos">
            <VideoList videos={videos}/>
          </Tab> */}
          <Tab eventKey="reader" title="Text Reader">
            <Pronunciation />
          </Tab>
          {/* <Tab eventKey="speeches" title="Inspired Talks">
            <BestSpeeches />
          </Tab> */}
        </Tabs>
      </main>
      <footer>
        <p>
          &copy; {new Date().getFullYear()} Dicverse.Com. All rights reserved.
        </p>
      </footer>
    </Container>
  );
}
